<template>
  <div class="home">
    <Navbar/>
    <router-view class="container"/>
  </div>
</template>

<script>
import Navbar from "@/components/NavBar/Navbar";

export default {
  name: "Managers",
  components: {Navbar},
}
</script>

<style scoped lang="scss">
  .home{
    width: 100%;
    height: 100%;
    background: #F8F8F8;
  }

  .container{
    width: 100%;
    height: 100%;
  }
</style>